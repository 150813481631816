import React from "react"
import Layout from '../components/global/layout.js'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import LandingSection from "../components/global/landingsection.js"
import ContentSection from "../components/global/contentsection.js"
import { Helmet } from "react-helmet"
import projectsStyles from '../style/projects.module.scss'
import Image from "../components/global/image.js"

import ShortInfo from "../components/projects/projects.shortinfo.js"
import Description from "../components/projects/projects.description.js"
import Navigation from "../components/projects/projects.navigation.js"

import img1 from "../img/projects/Mockup_XC_lg.jpg"
import img2 from "../img/projects/Mockup_XC_2.jpg"


const ExhibitorCatalogue = () => {

    const intl = useIntl()

    const subTitleObject =  <p>{intl.formatMessage({id: "Projects.clients.tradex"})}</p>
    const subTitle =  subTitleObject.props.children + " | 2021"

    return (

        <Layout>

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <title> Spherical Elephant | Exhibitor Catalogue </title>
                <meta name="description" content="Full-stack Software Design und Development - Ein Einblick in unsere Lieblingsprojekte"></meta>
            </Helmet>

            <LandingSection
                h1={<FormattedMessage id="Project.h1" />}
                title={<FormattedMessage id="Projects.exhibitorcatalogue" />}
                subtitle={subTitle}
            />

            <ContentSection>

                <ShortInfo 
                client = {<FormattedMessage id="Projects.clients.tradex" />}
                year = {2021}
                services = {[
                <FormattedMessage id="Services.design" />,
                <FormattedMessage id="Services.development" />,
                <FormattedMessage id="Services.infrastructure" />,
                ]}
                />
                <Description 
                project = {<FormattedMessage id="Projects.exhibitorcatalogue.project" />}
                contribution = {<FormattedMessage id="Projects.exhibitorcatalogue.contribution" />}
                technologies = {[
                <FormattedMessage id="Projects.technologies.vue.js" />,
                <FormattedMessage id="Projects.technologies.three.js" />,
                <FormattedMessage id="Projects.technologies.leaflet" />,
                <FormattedMessage id="Projects.technologies.docker" />]}
                img1 = {<Image className={projectsStyles.projectImage} src={img1} alt="Projects.exhibitorcatalogue.img1.alt" />}
                img2 = {<Image className={projectsStyles.floatingProjectImage} src={img2} alt="Projects.exhibitorcatalogue.img2.alt" />}
                />

            <Navigation
            prevName = {<FormattedMessage id="Projects.emticker" />}
            prevClient = {<FormattedMessage id="Projects.clients.infinitymedia" />}
            prevYear  = "2021"
            prevUrl = "../project_emticker"

            nextName = {<FormattedMessage id="Projects.digitalsignage" />}
            nextClient = {<FormattedMessage id="Projects.clients.infinitymedia" />}
            nextYear  = "2020"
            nextUrl = "../project_digitalsignage"/>
    
            </ContentSection>
        </Layout>


    )
}

export default ExhibitorCatalogue
